.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 1s;

    .label {
        box-sizing: border-box;
        width: 400px;
        max-width: calc(100vw - 2rem);
        padding: 5rem 3rem;
        border-radius: 3px;
        background: #000;
        color: #fff;
        text-align: center;
        font-size: 3rem;
        cursor: pointer;
    }
}